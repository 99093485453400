import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { Link, graphql } from "gatsby";

import avgHeatmap from "../assets/avg_heatmap.gif";
import experimentBuilder from "../assets/experiment-building-small.gif";
import recruiting from "../assets/recruiting.gif";
import gazereplay from "../assets/gazereplay.gif";

import Layout from "../components/Layout";
import SectionLayout from "../components/SectionLayout";
import SEO from "../components/seo";
import Blob from "../components/Blob";
import CardCTA from "../components/CardCTA";

const StyledImg = styled.img`
  min-width: 100%;
  ${"" /* min-height: 500px; */}
  ${"" /* margin: 2em 0 3em 0; */}

  ${"" /* > img { */}
    max-height: 100%;
    max-width: 100%;
    height: auto;
  ${"" /* } */}
  ${"" /* border-radius: 3px; */}
  ${"" /* box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2); */}
`;

const Gif = styled.img`
  border-radius: 3px;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
`;

const Centered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100% - 26px);
`;

const items = [
  {
    title: "Select the media type",
    content:
      "We optimise your Eye Tracking's settings based on the type of experiment you want to conduct. The four predefined types you can choose from are Packages, Prints, Websites and Creatives.",
  },
  {
    title: "Upload your media",
    content:
      "Upload any media you want from your computer. We support all of the popular formats like png, jpeg and webp.",
  },
  {
    title: "Structure the experiment flow",
    content:
      "You can customise your Eye Tracking experiment by including additional questions. By doing so, you can get instant survey-like data without the need of an external survey tool.",
  },
  {
    title: "Define the Areas of Interest",
    content:
      "Eye Tracking, by default, gives profoundly insightful data for your experiments. By defining Areas of Interest, you will be able to get a quantitative and focused breakdown of the Eye Tracking data, helping you make data-driven decisions easier.",
  },
  {
    title: "Specify your audience",
    content:
      "We have been there. The hardest thing when we are conducting marketing or product research is recruiting. We got your back, and we can help you recruit people from our own remote panel. Just specify your desired demographics and let us do the hard work.",
  },
];

class How extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    const images = [
      data.type.childImageSharp.fluid,
      data.upload.childImageSharp.fluid,
      data.builder.childImageSharp.fluid,
      data.aoi.childImageSharp.fluid,
      data.audience.childImageSharp.fluid,
    ];
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, "how"]}
        />
        <SectionLayout
          // description="Explore our features and take a glimpse of what we can offer you with Loceye's platform."
          isSwitchable
          title="How It Works"
          customStyle={{ padding: "50px 0 0 0" }}
        >
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-md-6 col-12">
                <StyledImg src={images[0].src} />
              </div>
              <div className="col-md-6 col-lg-5">
                <Centered>
                  <div className="switchable__text">
                    <h2>{items[0].title}</h2>
                    <p
                      style={{ color: "#555", lineHeight: 2, fontSize: "16px" }}
                    >
                      {items[0].content}
                    </p>
                  </div>
                </Centered>
              </div>
            </div>
          </div>
        </SectionLayout>

        {items.map((item, index) => {
          if (index === 0) return;
          if (index % 2 === 1) {
            return (
              <SectionLayout isSwitchable padding="1em">
                <div className="container">
                  <div className="row justify-content-around">
                    <div className="col-md-6 col-lg-5">
                      <Centered>
                        <div className="switchable__text">
                          <h2>{item.title}</h2>
                          <p
                            style={{
                              color: "#555",
                              lineHeight: 2,
                              fontSize: "16px",
                            }}
                          >
                            {item.content}
                          </p>
                        </div>
                      </Centered>
                    </div>
                    <div className="col-md-6 col-12">
                      <StyledImg src={images[index].src} />
                    </div>
                  </div>
                </div>
              </SectionLayout>
            );
          } else {
            return (
              <SectionLayout isSwitchable padding="1em">
                <div className="container">
                  <div className="row justify-content-around">
                    <div className="col-md-6 col-12">
                      <StyledImg src={images[index].src} />
                    </div>
                    <div className="col-md-6 col-lg-5">
                      <Centered>
                        <div className="switchable__text">
                          <h2>{item.title}</h2>
                          <p
                            style={{
                              color: "#555",
                              lineHeight: 2,
                              fontSize: "16px",
                            }}
                          >
                            {item.content}
                          </p>
                        </div>
                      </Centered>
                    </div>
                  </div>
                </div>
              </SectionLayout>
            );
          }
        })}

        <CardCTA />
      </Layout>
    );
  }
}

export default How;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    type: file(relativePath: { regex: "/type.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    upload: file(relativePath: { regex: "/upload.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    builder: file(relativePath: { regex: "/builder.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    audience: file(relativePath: { regex: "/audience.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    aoi: file(relativePath: { regex: "/aoi.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
  }
`;
