import React, { Component, Fragment } from "react";
import styled from "styled-components";

const BlobWrapper = styled.span`
  position: absolute;
  ${"" /* z-index */}
  width: ${(props) => props.width}%;
  z-index: -1;
  svg {
    width: ${(props) => props.width}%;
  }

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const color = "#f2f2f2";

const blobs = [
  <svg width="100%" height="100%" viewBox="0 0 600 600">
    <path
      d="M458.2 191.7c41 33.5 64.2 98.9 54.6 163.3-9.5 64.4-51.9 127.7-110 153.8-58 26.1-131.6 14.9-199.1-15.1-67.5-30.1-128.9-79.2-137.4-135s36-118.4 83.8-153.4c47.7-35.1 98.8-42.7 153.7-45.7 54.8-3 113.5-1.5 154.4 32.1z"
      fill={color}
    />
  </svg>,
  <svg width="100%" height="100%" viewBox="0 0 600 600">
    <path
      d="M398 223.5c34.9 42.2 73.3 83.2 66.5 114.6-6.7 31.3-58.5 52.9-109.4 78.1-50.9 25.3-100.9 54.1-149.6 44.4-48.7-9.8-96.1-58-108.3-113.8-12.3-55.7 10.4-118.9 50-162.1 39.7-43.2 96.2-66.5 140.3-56.5 44 10 75.6 53.2 110.5 95.3z"
      fill={color}
    />
  </svg>,
  <svg width="100%" height="100%" viewBox="0 0 600 600">
    <path
      d="M463.8 184.4c40.3 35.5 59.2 103.1 43.4 159.1-15.8 56.1-66.2 100.4-123.8 127.3-57.7 26.8-122.6 36.1-161.9 10.3-39.3-25.9-53.1-86.9-70.5-150.7-17.4-63.8-38.6-130.3-14.4-162.2 24.2-31.8 93.8-29 159.5-25.9 65.7 3.1 127.4 6.5 167.7 42.1z"
      fill={color}
    />
  </svg>,
  <svg width="100%" height="100%" viewBox="0 0 600 600">
    <path
      d="M472.3 179.4c38.3 31.3 48.1 102.2 25.9 152.5-22.1 50.3-76.1 79.9-127.2 97.7-51.2 17.9-99.5 23.9-146.3 8.4-46.7-15.5-92-52.5-113.3-106.3-21.4-53.8-18.8-124.4 16.6-155 35.4-30.6 103.7-21.2 171.3-20.6 67.7.5 134.7-7.9 173 23.3z"
      fill={color}
    />
  </svg>,
  <svg width="100%" height="100%" viewBox="0 0 600 600">
    <path
      d="M443.5 163.7c22.1 49.5 5.6 108.1-9.6 163.6-15.3 55.6-29.2 108.3-64.2 130-35 21.7-91 12.4-153.4-8.2-62.4-20.6-131.2-52.5-144-99.7-12.8-47.2 30.5-109.7 77.7-165 47.3-55.3 98.7-103.3 154.7-107.1 56-3.7 116.7 36.9 138.8 86.4z"
      fill={color}
    />
  </svg>,
  <svg width="100%" height="100%" viewBox="0 0 600 600">
    <path
      d="M409.8 225.9c37.4 33.4 75.9 79.3 78.2 136.1 2.3 56.8-31.7 124.4-89.8 159-58.1 34.6-140.4 36.1-207.3 4.3C124 493.6 72.6 428.6 75.4 371.2c2.7-57.5 59.7-107.3 106.2-142.8C228.1 193 264 172 300.1 171.9c36.1-.1 72.3 20.7 109.7 54z"
      fill={color}
    />
  </svg>,
];
const Blob = ({ seed, width }) => (
  <BlobWrapper width={width}>{blobs[seed]}</BlobWrapper>
);

Blob.defaultProps = {
  seed: 0,
  width: 100,
};

export default Blob;
