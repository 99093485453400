import React from "react";
import styled from "styled-components";

const Body = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #444;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Card = styled.div`
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  padding: 4rem;
  margin: 4rem;
  margin-top: 0;
  background-color: white;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 50%);
  background: linear-gradient(135deg, #3f97ff 0%, #62ffec 100%);
  background: linear-gradient(135deg, #aecaef 0%, #e1d7f2 100%);
  background-image: linear-gradient(to right, #b3ffab 0%, #12fff7 100%);
  background-image: linear-gradient(-45deg, #ffc796 0%, #ff6b95 100%);
  background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
  h1,
  p {
    ${"" /* font-weight: bold; */}
    align-text: center;
    ${""}
    /*
     */
    color: white;
  }
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  ${"" /* transform: translateY(calc(-50% - 14rem)); */}

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
    margin: 2rem;

    & > div {
      margin-bottom: 3rem;
    }
  }
`;

const CardCTA = ({}) => {
  return (
    <Card>
      <Column>
        <h1>Ready to Get Started?</h1>

        <Body>
          To learn how Loceye can improve your design process and increase
          conversion arrange to speak to one of our experts or just login and
          try our platform.
        </Body>
      </Column>
      <a
        style={{ marginLeft: "15px" }}
        href="https://app.loceye.io"
        className="btn btn--sm btn--primary type--uppercase "
      >
        <span className="btn__text">Start Testing Now</span>
      </a>
    </Card>
  );
};

export default CardCTA;
